<template>
  <div class="videoList overflow-y-auto">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div :class="{ 'short-video': type === 'hot' }" class="slideBox">
        <VideoBox @click.native="openVideo(item)" class="slideItem" :videoInfo="item" v-for="item in list" :key="item.id" />
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import { getSection } from '@/api/movies';
import { searchTagorUser } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';
import VideoBox from '@/components/VideoBox';

export default {
  name: 'tagVideo',
  components: {
    PullRefresh,
    VideoBox,
  },
  props: {
    topicId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tagId: '',
      tagName: '',
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 50, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.tagId = this.$route.query.id;
    this.tagName = this.$route.query.name;
    this.getList('refresh');
  },
  watch: {
    // list: function (newVal, oldVal) {
    //   if (newVal.length == 0) {
    //     this.isNoData = true;
    //   } else {
    //     this.isNoData = false;
    //   }
    // },
    '$route.query': function (newVal, oldVal) {
      if (newVal.keywords != oldVal.keywords) {
        this.getList('refresh');
      }
    },
  },
  methods: {
    openVideo(item) {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/horizontalVideo',
          query: {
            id: item.id,
            sectionId: item.freeArea ? item.sourceID : '',
          },
        });
      } else {
        this.$store.commit('video/DELETE_JUMPVIDEOLIST');
        this.$store.commit('video/SET_JUMPVIDEOLIST', {
          list: this.list,
          videoInfo: item,
        });
        this.$router.push('/shortVideoDetails');
      }
    },
    // 查询列表
    async getList(type) {
      // console.log(type, '====');
      try {
        let req1 = {
          keyword: this.$route.query.keywords,
          // sort: [{sortKey:this.$route.query.keywords,sortVal:0}],
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          videoType: this.type == 'new' ? 'MOVIE' : 'SP',
        };
        let res = await this.$Api(searchTagorUser, req1);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          // console.log(list, 'list===');
          // if(this.type=='new'){
          //   list = list.filter(item=> item.playTime>=300)
          // }else{
          //   list = list.filter(item=> item.playTime<300)
          // }

          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          // console.log(this.list.length, 'this.list.length===');

          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .videoBox .coverBox {
  height: 92px;
  width: 164px;
}
/deep/ .short-video .coverBox {
  height: 192px;
}
.videoList {
  height: calc(100vh - 134px);
  padding: 10px 20px 0;
  .slideBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .slideItem {
      width: 164px;
      margin-bottom: 10px;
    }
  }
}
</style>
