<template>
  <div class="search">
    <Search class="search" ref="search" @search="search" :searValue="searValue" />
    <div class="topicMore">
      <van-tabs
        v-model="activeName"
        :class="['homeTab oepnX search-resault-content']"
        title-active-color="rgb(0,0,0)"
        title-inactive-color="rgb(142,142,142)"
        color="#ff0d2c"
        animated
        swipeable
        :lazy-render="false"
      >
        <van-tab :title="item.label" v-for="(item,index) in tabList" :key="item.value" :class="{ 'full-item': index == '0' }">
          <VideoList :topicId="topicId" :keywords="keywords" :type="item.value" />
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import Search from '@/components/Search';
import VideoList from './videoList/index.vue';
export default {
  name: 'searchDetail',
  components: {
    Search,
    VideoList,
  },
  data() {
    return {
      searValue: '',
      topicId: '',
      keywords: [],
      activeName: 'new',
      tabList: [
        {
          label: '影视',
          value: 'new',
        },
        {
          label: '短视频',
          value: 'hot',
        },
      ],
    };
  },
  mounted(){
    this.searValue =this.$route.query.keywords;
  },
 
  methods: {
    setHistory(name) {
      let itemInfo = {
        name
      }
      var localStorageTag = [];
      let hasLocalStorageTag = JSON.parse(JSON.stringify(localStorage.getItem('localStorageTag')));
      let hasTag = '';
      if (hasLocalStorageTag && hasLocalStorageTag.length > 0) {
        hasTag = hasLocalStorageTag.indexOf(itemInfo.name)>0;
      }
      localStorageTag = hasLocalStorageTag&&JSON.parse(hasLocalStorageTag) ||[];
      if (!hasTag) {
        localStorageTag.push(itemInfo.name);
      }
      localStorage.setItem('localStorageTag', JSON.stringify(localStorageTag));
    },
    search(e) {
      this.searValue = e;
      this.setHistory(e);
      this.$router.replace('/search/detail?keywords='+e)
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .full-item .videoBox {
  height: 234px;
  .coverBox {
    height: 187px;
  }
  &.slideItem {
    width: 100% !important;
    .coverBox {
      width: 100%;
    }
  }
}
.search {
}
.topicMore {
  height: 100%;
  .back {
    position: absolute;
    left: 14px;
    top: 14px;
    height: 19px;
    width: 19px;
    z-index: 1;
    > svg {
      height: 16px;
      width: 13.3px;
    }
  }
}
/deep/ .van-tabs {
  .van-tabs__wrap {
    display: inline-block;
    margin-left: 10px;
    width: 100px;
  }
  .van-tab__text {
    font-size: 14px;
  }
  .van-tab--active {
    font-weight: 600;
  }
  .van-tabs__line {
    width: 14px;
    height: 6px;
    background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
    bottom: 20px;
  }
}
.search-resault-content{
  // padding-bottom: 40px;
}
</style>
