<template>
  <div class="search-box">
    <div class="back" @click="$router.go(-1)">
      <svg-icon icon-class="headArrow" />
    </div>
    <van-search
      shape="round"
      background="#ffffff"
      v-model="value"
      placeholder="请输入搜索关键词"
      class="input"
      :left-icon="leftIcon"
      @clear="clear"
      @blur="onChange"
      @input="$emit('input', value)"
    ></van-search>
    <div class="search" @click="$emit('search', value)">搜索</div>
  </div>
</template>
<script>
import { Search } from 'vant';

export default {
  props: {
    searValue: {
      type: String,
    },
  },
  data() {
    return {
      value: '',
      leftIcon: require('@/assets/png/Search.png'),
    };
  },
  components: {
    [Search.name]: Search,
  },
  watch: {
    searValue: {
      handler(newName, oldName) {
        this.value = newName;
      },
      immediate: true,
    },
  },
  methods: {
    onChange(e){
      this.value = e.target.value ;
    },
    clear() {
      this.$emit('clear');
    },
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #ffffff;

  .back {
    > svg {
      height: 19px;
      width: 19px;
    }
  }

  .input {
    width: 100%;
    height: 100%;
    color: #666666;
    background-color: #ffffff;
  }

  /deep/ .van-search__content {
    background: #f2f5f6;
    height: 40px;
    ::placeholder {
      color: #999999;
    }
  }

  /deep/ .van-field__control {
    color: #666666;
  }

  /deep/ .van-field__left-icon {
    display: flex;
    align-items: center;
    padding-left: 8px;
  }

  /deep/ .van-icon {
    font-size: 20px;
  }

  /deep/ .van-field__body {
    margin-top: 4px;
    margin-left: 5px;
  }

  .search {
    width: 61px;
    height: 41px;
    background: linear-gradient(to right, rgb(247, 77, 129), rgb(252, 169, 132));
    border-radius: 14px;
    text-align: center;
    line-height: 41px;
    color: white;
    font-size: 13px;
  }
}
</style>
